<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <div>
    <v-row align="center">
      <v-col cols="6" class="title pr-0 text-left">
        <div class="title d-inline-flex">
          <h1>{{ $t('nodes.tree.title') }}</h1>
          <v-tooltip slot="append" bottom>
            <template #activator="{ on }">
              <v-icon class="cursor-help ml-2" color="primary" v-on="on"> info </v-icon>
            </template>
            <span>{{ $t('nodes.tree.multiselectInfoMsg') }}</span>
          </v-tooltip>
        </div>
        <v-divider class="pl-2 mb-0" />
      </v-col>
      <v-col cols="6" class="text-right">
        <v-tooltip v-if="nodesBackup.length" left>
          <template #activator="{ on, attrs }">
            <v-btn
              id="iiotNodeTreeRevert"
              v-bind="attrs"
              fab
              x-small
              class="primary-tttech-background-btn"
              dark
              v-on="on"
              @click="$store.dispatch('node-tree/revert_latest_change')"
            >
              <v-icon dark> mdi-undo-variant </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('nodes.tree.revertLastChange') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="11">
        <v-text-field
          id="iiotNodeTreeSearchInput"
          ref="nodeTreeSearch"
          v-model="search"
          :label="$t('defaultTable.search')"
          prepend-icon="mdi-magnify"
          class="default-search-input"
          clearable
          autofocus
          @keydown.esc="(search = ''), searchTreeNodes()"
          @click:clear="(search = ''), searchTreeNodes()"
          @click:prepend="searchTreeNodes()"
          @keydown.enter="searchTreeNodes()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Logger from '@/utils/logger';

export default {
  data() {
    return {
      search: '',
    };
  },
  computed: {
    nodesBackup() {
      return this.$store.getters['node-tree/getLastChangeNodesBackup'];
    },
  },
  methods: {
    searchTreeNodes() {
      try {
        this.$store.dispatch('node-tree/search_node_tree', { searchTerm: this.search });
      } catch (e) {
        Logger.error(e);
      }
    },
  },
};
</script>
